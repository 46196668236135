<template>
  <div>
    <!--    <el-alert-->
    <!--      slot="toolbar"-->
    <!--      style="margin-bottom: 0px"-->
    <!--      title="入网申请将由平台管理员审核后生成电梯档案，如需加急处理请联系平台管理员！"-->
    <!--      center-->
    <!--      :closable="false"-->
    <!--      type="error">-->
    <!--    </el-alert>-->
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search"  @clear="clearDateRange" url="elevatorNetApply">
      <template slot="toolbar">
        <el-button class="addBtn" v-if="platformNoAuth" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>

        <!-- <el-button v-if="showCheck && false" @click="$refs.batchSetting.open()">
          {{ $l("elevatorNetApply.downloadTemplate", "批量设置") }}
        </el-button> -->
        <el-button class="greenBtn" @click="downloadTemplate()">
          {{ $l("elevatorNetApply.downloadTemplate", "下载导入模板") }}
        </el-button>

        <el-upload :action="uploadUrl" :before-upload="beforeUpload" :on-success="onUploadSuccess"
          :on-error="onUploadError" :on-exceed="onUploadExceed" :show-file-list="false" :headers="uploadHeaders"
          accept=".xlsx" :limit="1" :disabled="importLoading" style="display: inline-block;">
          <el-button v-if="platformNoAuth" :loading="importLoading" style="margin: 0 10px;">
            {{ $l("elevatorNetApply.importElevator", "导入电梯") }}
          </el-button>
        </el-upload>
      </template>
      <template slot="adSearch">
          <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
            <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
          </vm-search>
          <vm-search :label="$l('elevatorNetApply.createSource', '创建来源')">
            <el-select v-model.trim="search.createType" :placeholder="$t('common.pleaseSelect')" style="width: 100%"
              clearable>
              <el-option v-for="item in typeOptions" :key="item.value" :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </vm-search>
          <!--          <vm-search :label="$l('elevatorNetApply.createSource','审核状态')">-->
          <!--            <el-select-->
          <!--              v-model.trim="search.isCheck"-->
          <!--              :placeholder="$t('common.pleaseSelect')"-->
          <!--              style="width: 100%"-->
          <!--              clearable>-->
          <!--              <el-option-->
          <!--                v-for="item in checkTypeOptions"-->
          <!--                :key="item.value"-->
          <!--                :label="item.label"-->
          <!--                :value="item.value"></el-option>-->
          <!--            </el-select>-->
          <!--          </vm-search>-->
          <vm-search :label="$l('msgPushRecord.createTime', '创建时间')">
            <el-date-picker
              v-model="dateRange"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              :unlink-panels="true"
              :range-separator="$t('common.to')"
              :start-placeholder="$t('common.startDate')"
              :end-placeholder="$t('common.endDate')"
              :default-time="['00:00:00', '23:59:59']"
              @change="handleDateChange">
            </el-date-picker>
          </vm-search>
      </template>
      <el-table-column prop="dtuCode" :label="$l('elevatorNetApply.dtuCode', '终端编号')" width="120" align="center">
        <template slot-scope="scope">
          <router-link v-if="scope.row.elevatorId" :to="'/elevator/' + scope.row.elevatorId" target="_blank">
            {{ scope.row.dtuCode }}
          </router-link>
          <div v-else>{{ scope.row.dtuCode }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="factoryNumber" :label="$l('elevatorNetApply.factoryNumber', '出厂编号')" width="140"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip :label="$l('elevatorNetApply.elevatorName', '电梯名称')">

      </el-table-column>
      <el-table-column prop="useUnit" show-overflow-tooltip
        :label="$l('elevatorNetApply.useUnit', '使用单位')"></el-table-column>
      <el-table-column prop="createComName" show-overflow-tooltip
        :label="$l('elevatorNetApply.useUnit', '制造单位')"></el-table-column>
      <el-table-column prop="maintComName" show-overflow-tooltip
        :label="$l('elevatorNetApply.useUnit', '维保单位')"></el-table-column>
      <!-- <el-table-column prop="propertyCom" show-overflow-tooltip
        :label="$l('elevatorNetApply.useUnit', '物业单位')"></el-table-column> -->
      <el-table-column prop="userName" show-overflow-tooltip :label="$l('gatewayVersion.creator', '创建人')"
        width="140"></el-table-column>
      <el-table-column prop="isWeb" :label="$l('elevatorNetApply.createSource', '来源')" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.createType === 1">
            {{ $l("userLoginLog.web", "网页新增") }}
          </span>
          <span v-if="scope.row.createType === 2">
            {{ $l("userLoginLog.app", "网页导入") }}
          </span>
          <span v-if="scope.row.createType === 3">
            {{ $l("userLoginLog.app", "APP") }}
          </span>
          <span v-if="scope.row.createType === 4">
            {{ $l("userLoginLog.app", "API导入") }}
          </span>
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="isCheck"-->
      <!--        :label="$l('elevator.joinStatus','审核状态')"-->
      <!--        width="100px"-->
      <!--        align="center">-->
      <!--        <template v-slot="scope">-->
      <!--          <el-tag :type="scope.row.isCheck === 1 ? 'success' : 'info'">-->
      <!--            {{scope.row.isCheck === 1 ? $l("elevator.joined", "已审核") : $l("elevator.notJoined", "审核中")}}-->
      <!--          </el-tag>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column prop="createTime" :label="$l('msgPushRecord.createTime', '创建时间')" width="140"
        align="center"></el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="90" fixed="right">
        <template slot-scope="scope">
          <!--          <el-button v-if="showCheck" type="success" @click="$refs.check.open(scope.row.id)">-->
          <!--            {{$l("elevatorNetApply.generate", "审核")}}-->
          <!--          </el-button>-->
          <el-button type="text" @click="edit(scope.row)">
            {{ $l("elevatorNetApply.edit", "编辑") }}
          </el-button>
          <el-button class="operateDelBtn" type="text" @click="handleDelete(scope.row)">{{ $l("elevatorNetApply.delete",
            "删除") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <batch-setting ref="batchSetting" @save-success="getList(-1)"></batch-setting>
    <net-apply-check ref="check" @save-success="getList(-1)"></net-apply-check>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
  </div>
</template>
<script>
import EditPage from "./Edit";
import NetApplyCheck from "@/views/elevatorNetApply/NetApplyCheck";
import auth from "@/util/auth";
import BatchSetting from "@/views/elevatorNetApply/BatchSetting";

export default {
  components: { EditPage, NetApplyCheck, BatchSetting },
  data() {
    return {
      importLoading: false,
      platformNoAuth: auth.getCurrentOrgId() != 0,
      showCheck: auth.getUsername() === "superAdmin",
      uploadUrl: window.config.SERVER_URL + "/elevatorNetApply/importElevator",
      uploadHeaders: { Authorization: auth.getToken() },
      loading: true,
      dateRange: [],
      search: {
        filter: "",
        createType: "",
        isCheck: "",
        startTime: "",
        endTime: "",
      },
      typeOptions: [
        { value: 1, label: this.$l("newMsgSend.msgPush", "网页新增") },
        { value: 2, label: this.$l("newMsgSend.email", "网页导入") },
        { value: 3, label: this.$l("newMsgSend.email", "APP") },
        { value: 4, label: this.$l("newMsgSend.email", "API导入") },
      ],
      checkTypeOptions: [
        { value: 0, label: this.$l("newMsgSend.msgPush", "审核中") },
        { value: 1, label: this.$l("newMsgSend.email", "已审核") },
      ],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    edit(row) {
      this.$refs.editPage.open(row.id);
      // if (row.isCheck === 1) {
      //   this.$message.error("已审核不可再编辑");
      // }
      // if (row.isCheck === 0) {
      //   this.$refs.editPage.open(row.id);
      // }
    },
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("elevator.name") + " " + row.elevatorName + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), { type: "warning" }).then(() => {
        this.$http.delete("elevatorNetApply", row.id).then(() => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        });
      });
    },
    create(row) {
      this.$confirm(this.$l("common.generate", "生成电梯档案") + " ( " + this.$l("elevator.dtuCode", "终端编号") + " " + row.dtuCode + " )," + this.$l("common.isGenerate", "确定吗") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.loading = true;
        this.$http.post(`elevatorNetApply/create/${row.id}`).then(() => {
          this.loading = false;
          this.getList(1);
          this.$message.success(this.$l("elevatorNetApply.generateSuccess", "生成成功"));
        }).catch(() => {
          this.loading = false;
        });
      });
    },
    downloadTemplate() {
      if (this.$i18n.isCn) {
        window.open("/file/入网模板.xlsx", "_self");
      } else {
        window.open("/file/NetTemplate.xlsx", "_self");
      }
    },
    beforeUpload(file) {
      let fileName = file.name;
      let index = fileName.lastIndexOf(".");
      let lastName = fileName.substring(index, fileName.length);
      if (lastName.toLowerCase() !== ".xlsx") {
        this.$message.error(this.$l("elevatorNetApply.uploadType", "请上传.xlsx文件类型"));
        return false;
      }
      this.importLoading = true;
      return true;
    },
    onUploadSuccess() {
      this.importLoading = false;
      this.$message.success(this.$l("elevatorNetApply.importSuccess", "导入成功"));
      this.getList(1);
    },
    onUploadError(err) {
      this.importLoading = false;
      if (!err.message) {
        this.$message.error(this.$l("elevatorNetApply.importFailure", "导入失败"));
      } else {
        console.log(JSON.parse(err.message).message);
        console.log(err.message);
        this.$message.error(this.$l("elevatorNetApply.importFailure", "导入失败") + ": " + JSON.parse(err.message).message);
      }
    },
    onUploadExceed() {
      this.$message.error(this.$l("elevatorNetApply.importOnly", "一次只能导入1个文件"));
    },
    handleDateChange(dateRange) {
      if (dateRange != null) {
        this.search.startTime = dateRange[0];
        this.search.endTime = dateRange[1];
      } else {
        this.search.startTime = "";
        this.search.endTime = "";
      }
    },
    clearDateRange() {
      this.dateRange = [];
    },
  },
};
</script>
