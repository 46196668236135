<template>
  <el-dialog
    v-el-drag-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :title="model.id?$l('elevatorNetApply.editElevatorNetApply','编辑入网申请'):$l('elevatorNetApply.addElevatorNetApply','新增入网申请')"
    width="1300px"
    class="adaptationModal"
    @close="$reset('form')">
    <el-form
      ref="form"
      :label-width="$l('elevatorNetApply.labelWidth','120px')"
      :model="model">
      <div>
        <el-divider content-position="left">{{$l("elevatorNetApply.companyInfo", "企业信息")}}</el-divider>
        <div class="vm-separate">
          <el-form-item :label="$l('elevatorNetApply.useUnit','使用单位')" prop="useUnit" :rules="$rule.notNull">
            <el-autocomplete
              v-model="model.useUnit"
              class="inline-input"
              :fetch-suggestions="useUnitQuerySearch"
              :placeholder="$t('common.pleaseEnter')"
              :trigger-on-focus="false"
              style="width: 100%"
              @select="handleSelect"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="$l('elevatorNetApply.useUnitDistrict','使用单位地区')"
            prop="useUnitDistrictId">
            <vm-district-select
              v-model="model.useUnitDistrictId"
              :placeholder="$t('common.pleaseSelect')">
            </vm-district-select>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item
            :label="$l('elevatorNetApply.useUnitAddress','使用单位详细地址')"
            prop="useUnitAddress">
            <el-input v-model="model.useUnitAddress" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$l('elevatorNetApply.proName','项目名称')" prop="projectName">
            <el-input
              v-model.trim="model.projectName"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.manufactured')" prop="createComName">
            <el-autocomplete
              v-model="model.createComName"
              class="inline-input"
              :fetch-suggestions="companyQuerySearch"
              :placeholder="$t('common.pleaseEnter')"
              :trigger-on-focus="false"
              style="width: 100%"
              @select="handleSelect"
            >
            </el-autocomplete>
          </el-form-item>
          <el-form-item
            :label="$t('elevatorNetApply.maintComName')"
            prop="maintComName">
            <el-autocomplete
              v-model="model.maintComName"
              class="inline-input"
              :fetch-suggestions="companyQuerySearch"
              :placeholder="$t('common.pleaseEnter')"
              :trigger-on-focus="false"
              style="width: 100%"
              @select="handleSelect"
            >
            </el-autocomplete>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.maintEmpName')" prop="maintEmpName">
            <el-input v-model.trim="model.maintEmpName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('elevatorNetApply.maintEmpPhone')" prop="maintEmpPhone">
            <el-input
              v-model.trim="model.maintEmpPhone"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div class="vm-separate">
          <el-form-item :label="$t('elevatorNetApply.propertyCom')" prop="propertyCom">
            <el-autocomplete
              v-model="model.propertyCom"
              class="inline-input"
              :fetch-suggestions="companyQuerySearch"
              :placeholder="$t('common.pleaseEnter')"
              :trigger-on-focus="false"
              style="width: 100%"
              @select="handleSelect"
            >
            </el-autocomplete>
          </el-form-item>
          <div></div>
        </div>
      </div>
      <div>
        <div style="width: 68%;float: left;padding-right: 2%">
          <el-divider content-position="left">{{$t("elevatorNetApply.elevatorInfo")}}</el-divider>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.elevatorName')" prop="elevatorName" :rules="$rule.notNull">
              <el-input v-model="model.elevatorName" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.factoryNumber')" prop="factoryNumber" :rules="$rule.notNull">
              <el-input
                v-model="model.factoryNumber"
                :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('elevator.whereUsed','物联网')" prop="isInternet">
              <el-select v-model="model.isInternet" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                <el-option
                  v-for="item in internetList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="model.isInternet === 1" :label="$t('elevatorNetApply.dtuCode')" prop="dtuCode" :rules="$rule.notNull">
              <el-input v-model="model.dtuCode" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <div v-if="model.isInternet === 0"></div>
          </div>
          <div class="vm-separate">
            <el-form-item label="设备代码" prop="deviceId" :rules="$rule.notNull">
              <el-input v-model.trim="model.deviceId" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item label="注册代码" prop="regCode">
              <el-input v-model.trim="model.regCode" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.elevatorModel')" prop="elevatorModel">
              <el-input v-model="model.elevatorModel" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.brand')" prop="brand">
              <el-input v-model.trim="model.brand" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$l('elevator.whereUsed','系统厂家')" prop="systemCompany" :rules="$rule.notNull">
              <el-select v-model="model.systemCompany" :placeholder="$t('common.pleaseSelect')" style="width: 100%">
                <el-option
                  v-for="item in systemCompanyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.elevatorType')" prop="elevatorProductTypeId">
              <el-select
                v-model="model.elevatorProductTypeId"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%">
                <el-option
                  v-for="item in productTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.speed')" prop="speed">
              <el-input v-model.trim="model.speed" type="number" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.load')" prop="load">
              <el-input v-model="model.load" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevatorNetApply.exFactoryDate')" prop="factoryDate">
              <el-date-picker
                v-model="model.factoryDate"
                type="date"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item :label="$t('elevatorNetApply.installationDate')" prop="installDate">
              <el-date-picker
                v-model="model.installDate"
                type="date"
                :placeholder="$t('common.pleaseSelect')"
                style="width: 100%"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
          </div>
          <div class="vm-separate">
            <el-form-item :label="$t('elevator.whereUsed')" prop="applyPlaceId">
              <el-select v-model="model.applyPlaceId" :placeholder="$t('elevator.whereUsed')" style="width: 100%">
                <el-option
                  v-for="item in placeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <div></div>
          </div>
        </div>
        <div style="width: 30%;float: left;">
          <el-divider content-position="left">{{$l("elevatorNetApply.floorMappings", "楼层对照")}}</el-divider>
          <el-table
            ref="vmTable"
            height="360px"
            border
            :data="model.floorMappings">
            <el-table-column
              type="index"
              width="50">
            </el-table-column>
            <el-table-column
              prop="floorLocation"
              :label="$l('elevatorNetApply.floorLocation','楼层位置')"
              align="center"
              width="120">
              <template slot-scope="scope">
                <el-input v-model="scope.row.floorLocation"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="code" :label="$l('elevatorNetApply.displayCode','显示代码')" align="center">
              <template slot-scope="scope">
                <el-input v-model="scope.row.code"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              :label="$l('elevatorNetApply.operate','操作')"
              align="center"
              width="100px">
              <template slot-scope="scope">
                <el-button type="danger" @click="model.floorMappings.splice(scope.$index, 1);">
                  {{$l("elevatorNetApply.delete", "删除")}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-bottom: 10px">
            <el-button type="text" @click="addRow">
              + {{$l("elevatorNetApply.addFloorMappings", "请添加楼层信息")}}
            </el-button>
          </div>
        </div>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$l("elevatorNetApply.cancel", "取消")}}</el-button>
      <el-button
        v-if="!model.elevatorId && model.id > 0"
        type="success"
        :loading="loading"
        @click="create">{{$l("elevatorNetApply.cancel", "生成")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$l("elevatorNetApply.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import VmDistrictSelect from "../../components/VmDistrictSelect";

  const moduleName_productType = "elevatorProductTypes";

  export default {
    components: {VmDistrictSelect},
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        loading: false,
        activeTab: "first",
        useUnitList: [],
        companyList: [],
        productTypeList: [],
        placeList: [],
        model: {
          id: 0,
          isInternet: 1,
          systemCompany: "",
          useUnit: "",
          useUnitAddress: "",
          createComName: "",
          maintComName: "",
          maintEmpName: "",
          maintEmpPhone: "",
          projectName: "",
          propertyCom: "",
          dtuCode: "",
          nodeCode: "",
          elevatorProductTypeId: "",
          brand: "",
          elevatorModel: "",
          load: "",
          speed: "",
          elevatorId: "",
          regCode: "",
          deviceId: "",
          factoryNumber: "",
          elevatorName: "",
          factoryDate: "",
          installDate: "",
          floorMappings: [],
          useUnitDistrictId: 0,
          isCreate: false,
        },
        systemCompanyList: [
          { value: 1, label: this.$l("newMsgSend.msgPush", "新时达") },
          { value: 2, label: this.$l("newMsgSend.email", "佳格") },
          { value: 3, label: this.$l("newMsgSend.email", "汇川") },
          { value: 4, label: this.$l("newMsgSend.email", "安驰") },
          { value: 5, label: this.$l("newMsgSend.email", "英旭") },
          { value: 6, label: this.$l("newMsgSend.email", "航川") },
        ],
        internetList: [
          {value: 1, label: "有"},
          {value: 0, label: "无"},
        ],
      };
    },
    methods: {
      useUnitQuerySearch(queryString, cb) {
        if (this.useUnitList && this.useUnitList.length) {
          cb(this.filterQueryString(this.useUnitList, queryString));
        } else {
          this.$http.get("companies/useUnit")
            .then(res => {
              this.useUnitList = res.data.map(item => {
                return {
                  value: item.name,
                };
              });
              cb(this.filterQueryString(this.useUnitList, queryString));
            });
        }
      },
      getPlaceList() {
        this.$api.getList("dicts/ApplyPlace").then(response => {
          this.placeList = [];
          for (let place of response.data) {
            let item = {value: place.code, label: place.name};
            this.placeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      companyQuerySearch(queryString, cb) {
        if (this.companyList && this.companyList.length) {
          cb(this.filterQueryString(this.companyList, queryString));
        } else {
          this.$http.get("companies/list")
            .then(res => {
              this.companyList = res.data.map(item => {
                return {
                  value: item.name,
                };
              });
              cb(this.filterQueryString(this.companyList, queryString));
            });
        }
      },
      filterQueryString(list, queryString) {
        return list.filter(item => {
          return (item.value.toLowerCase().indexOf(queryString.toLowerCase()) >= 0);
        });
      },
      handleSelect(item) {
      },
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getAll();

        if (this.model.id) {
          this.getData();
        }
        // this.model.applyPlaceId = 13;
      },
      getData() {
        this.$http
          .get("elevatorNetApply/" + this.model.id)
          .then(({data}) => {
            //this.$assign(this.model, data);
            this.model = Object.assign(this.model, data);
          });
      },
      addRow() {
        this.model.floorMappings.push({
          code: "",
          floorLocation: "",
        });
      },
      create() {
        this.$confirm(this.$l("common.generate", "生成电梯档案") + " ( " + this.$l("elevator.dtuCode", "终端编号") + " " + this.model.dtuCode + " )," + this.$l("common.isGenerate", "确定吗") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.loading = true;
          this.$http.save("elevatorNetApply", this.model).then(() => {});

          this.$http.post(`elevatorNetApply/create/${this.model.id}`).then(() => {
            this.loading = false;
            this.dialogVisible = false;
            this.$emit("save-success");
            this.$message.success(this.$l("elevatorNetApply.generateSuccess", "生成成功"));
          }).catch(() => {
            this.loading = false;
          });
        });
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (!this.model.floorMappings.length) {
            this.$message.error(this.$t("common.tip.completeFloorInformation"));
            return;
          }
          if (!this.model.factoryNumber && !this.model.regCode) {
            this.$message.error(this.$t("common.tip.optional"));
            return;
          }
          if (valid) {
            this.submitLoading = true;
            this.$http.save("elevatorNetApply", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          }
        });
      },
      getAll() {
        this.$axios.all([this.getProductTypeList(), this.getPlaceList()]).then(this.$axios.spread(function(acct, perms) {
        }));
      },
      getProductTypeList() {
        this.$api.getList(moduleName_productType).then(response => {
          this.productTypeList = [];
          for (let productType of response.data) {
            let item = "";
            if (this.$i18n.isCn) {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeName};
            } else {
              item = {value: productType.elevatorProductTypeID, label: productType.elevatorProductTypeNameEn};
            }
            this.productTypeList.push(item);
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
