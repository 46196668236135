<template>
  <el-cascader
    ref="cascader"
    v-model="defaultValue"
    :options="options"
    :props="props"
    :change-on-select="changeOnSelect"
    clearable
    style="width: 100%"></el-cascader>
</template>

<script>
  const moduleName = "districts";
  const storage = window.localStorage;
  const welcome = window.config.siteName;

  export default {
    props: {
      value: {
        type: Number,
        default: 0,
      },
      changeOnSelect: Boolean,
    },
    data() {
      return {
        options: [],
        defaultValue: [],
        props: {
          label: "name",
          value: "id",
        },
      };
    },
    watch: {
      value: function(val) {
        if (val) {
          if (val === 0) {
            this.defaultValue = [];
            return;
          }
          if (val === this.defaultValue[this.defaultValue.length - 1]) {
            return;
          }
          this.$axios.get("districts/cascade/" + val).then(res => {
            this.defaultValue = res.data;
          }).catch(error => {
            this.$message.error("获取省市区数据失败，" + error.response.data.message);
          });
        } else {
          this.defaultValue = [];
        }
      },
      defaultValue: function(val) {
        let id = val[val.length - 1];
        if (id) {
          this.$emit("input", id);
          this.$parent.$emit("el.form.change");
          let labels = this.currentLabels();
          this.$emit("onChange", val, labels);
        } else {
          this.$emit("clear");
        }
      },
    },
    mounted() {
      this.getDistricts();
    },
    methods: {
      getDistricts() {
        let data = storage.getItem("iot_" + moduleName);
        if (data) {
          this.options = JSON.parse(data);
        } else {
          this.$api.getList(moduleName).then(res => {
            this.options = res.data;
            storage.setItem("iot_" + moduleName, JSON.stringify(res.data));
          }).catch(error => {
            this.$message.error("获取省市区数据失败，" + error.response.data.message);
          });
        }
        // 马来西亚平台gis地图、监控中心等只显示本地城市
        if (welcome.indexOf("Welcome to Remote Offsite System") > -1) {
          let optionsTemp = [];
          for (let item of this.options) {
            if (item.id >= 4374) {
              optionsTemp.push(item);
            }
          }
          this.options = optionsTemp;
        }
      },
      currentLabels() {
        let options = this.options;
        let labels = [];
        this.defaultValue.forEach(value => {
          const targetOption = options && options.filter(option => option.id === value)[0];
          if (targetOption) {
            labels.push(targetOption.name);
            options = targetOption.children;
          }
        });
        return labels;
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
